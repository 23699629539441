<template>
  <div class="main">
    <div class="topbar">
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template>
              <Select v-model="areaClass"
                      placeholder="区域等级"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in areaClassArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="areaName"
                     placeholder="区域名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            <Button type="primary"
                    class="m-r-10">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll">
          <Tree :data="treeData"
                style="padding:0 10px"></Tree>
        </div>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="columnsRail"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      areaClass: 0,
      areaClassArr: [{
        id: 0,
        name: '全部'
      },
      {
        id: 1,
        name: '省'
      },
      {
        id: 2,
        name: '自治区'
      }, {
        id: 3,
        name: '直辖市'
      }, {
        id: 4,
        name: '特别行政区'
      },
      ],
      areaName: '',
      treeData: [{
        title: '智慧环卫演示版gs',
        value: 1,
        children: [{
          title: '奎文区',
          value: 11,
          children: [{
            title: '智慧环卫演示版gs',
            value: 111,
            children: [{
              title: '保洁队',
              value: 1111
            }, {
              title: '公厕队',
              value: 1112
            }, {
              title: '运输队',
              value: 1113
            }, {
              title: '建筑队',
              value: 1114
            }, {
              title: '园林队',
              value: 1115
            }]
          }]
        }]
      }],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsRail: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '区域名称',
        key: 'areaName',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '上级区域',
        key: 'areaSuperior',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '区域等级',
        key: 'areaClass',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '责任人',
        key: 'principal',
        tooltip: true,
        width: 110,
        align: 'center'
      },
      {
        title: '联系电话',
        key: 'phone',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '面积',
        key: 'area',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '区域样式',
        key: 'areaStyle',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '备注',
        key: 'remark',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        align: 'center'
      }
      ],
      data: [],
      selectArr: []
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 300
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 300
      }
      this.getList()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
